import React, { useEffect, useState } from 'react';
import '../../src/assets/styles/metricas.css';
import municipios from '../icons/municipios.gif'
import department from '../icons/Departamento.png'
import project from '../icons/proyectos.png';
import beneficiarios from '../icons/beneficiarios.png'

const IconNumberComponent = ({ icon, number, text }) => {
  const [count, setCount] = useState(0);

  const handleScroll = () => {
    const element = document.getElementById('icon-number-component');
    const rect = element.getBoundingClientRect();

    if (rect.top < window.innerHeight && rect.bottom >= 0) {
      let i = 0;
      const incrementCount = () => {
        if (i <= number) {
          setCount(i);
          i++;
          setTimeout(incrementCount, 0.3); // Ajusta el intervalo de incremento (en milisegundos) para controlar la velocidad de la animación
        }
      };
      incrementCount();

      window.removeEventListener('scroll', handleScroll);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="icon-number-component" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ marginRight: '10px' }}>
        <img src={icon} alt="Icon" width="75" height="75" />
      </div>
      <div style={{ marginRight: '50px' }}>
        <h2 style={{ fontFamily: 'kanit,sans-serif', fontWeight: '500', fontSize: '48px', lineHeight: '50px' }}>{count}</h2>
        <p style={{ fontFamily: 'kanit,sans-serif', fontWeight: '500', fontSize: '18px', lineHeight: '21px', color: '#1e375a' }}>{text}</p>
      </div>
    </div>
  );
};

export const Metricas = () => {
  return (
    <div className='metricas'>
      <IconNumberComponent icon={municipios} number={2} text="Municipios" />
      <IconNumberComponent icon={department} number={1} text="Departamentos" />
      <IconNumberComponent icon={project} number={5} text="Proyectos" />
      <IconNumberComponent icon={beneficiarios} number={360} text="Beneficiados" />
    </div>
  );
};
