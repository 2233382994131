import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../src/assets/styles/progressBar.css';
import React, {useEffect, useState} from 'react';
import { FaCircle } from 'react-icons/fa';

// Usestate mantiene el estado del progreso de la barra now
// UseEffect se usa para agregar y remover un evento de listener al evento de Scroll


export  const ProgresBarr = (props) => {


    const {subtitle, title, description, maxProgress} = props;

    const [now, setNow] = useState(0) // Declaración de las variables de control del porcentaje de progreso
         
    // Control de evento Scroll para activación de la visualización
    useEffect(() => {
      const handleScroll = () => {
      const scrollTop = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;

      const maxPercent = maxProgress; // Asigna  la variable maxPercent el valor de maxProgress recibido desde el props que se envía desde App.jsx
      const scrollPercentage = (scrollTop / (documentHeight - windowHeight)) * maxPercent;
      setNow(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


 

  return (
    <div className='title-container-progress-bar'> 
        <h2 style={{textAlign:'left', fontWeight:'500', tabSize:'40px', lineHeight:'48px', color:'#1e375a', marginLeft:'10%'}}>{subtitle}</h2> {/*Se definen los estilos de h2 */}
        <div className='container-progress-bar'>
            {/* Estructura para el dinamismo del progres bass, los títulos y los lottie */}
            <div className='letf-container-lottie-progress-bar-percent'>
              {`${Math.round(now)}%`}
            </div>
            <div className='rigth-container-progress-bar-percent'>
                <p style={{textAlign:'left', fontWeight:'500', fontSize:'14px', lineHeight:'14px', color:'#495057', marginTop:'7%'}}>{description} </p>
                <ProgressBar now={now}   style={{ height: '3px'}} animationDuration={4000} />
                <FaCircle
                style={{
                position: 'absolute',
                top: '-4px',
                right: '0',
                transform: 'translate(50%, -50%)',
                fontSize: '8px',
                color: '#000',
                zIndex: '1',
            }}
        />
            </div>           
        </div>
    </div>
  
  );
}

