import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import weAreWorking from '../../../lotties/RobotAgricultura.json';
import '../../../assets/styles/donar.css';
// import imgGrupoSantiago from '../../../assets/img/donar/santiago.jpeg';

export const Donar = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.epayco.co/checkout.js';
        script.setAttribute('data-epayco-key', 'e370565ff2f6ce3c11e7b256149c041e');
        // Otros atributos aquí...

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className=''>
            
            <div className='div-container-img-donaciones'>
                <img src= "https://images-cctet-project.s3.us-west-2.amazonaws.com/santiago.jpeg" className='img-handdle-robot' alt="imagen proyectos stem 2023 Guapi Cauca"  />                
            </div> 
            
            <div className='main-container-donar'>

                <div className='left-container-donar'>
                        <h2 className='h2-style-title'>
                            Con tu aporte ayudas al fortalecimiento de este programa
                        </h2>

                    <p className='p-style-description'>
                        En CCTET, nuestra misión es cerrar las brechas en el acceso y permanencia en la educación superior,
                        así como en el acceso a herramientas tecnológicas y educativas en zonas vulnerables. Trabajamos para
                        respaldar a niños, niñas, adolescentes y jóvenes que desean transformar sus vidas mediante la
                        educación y la tecnología. Lo logramos a través de nuestro programa "Yo te guío", un programa que
                        busca fortalecer habilidades STEM, trabajo en equipo y de liderazgo a niños, niñas y adolescentes que
                        cursan el grado Octavo de secundaria y busca acompañarlos hasta su proceso de formación universitario.
                        <br /> <br />

                        Si crees en lo que hacemos y quieres ayudar, únete. Cada aporte suma e impacta positivamente en
                        nuestro proceso. <br /> <br />

                        Realiza tu donación en el botón de abajo.
                    </p>

                    {/* INSERT BOTÓN DE DONACIÓN EPAYCO */}
                        <div className='boton-epayco-donacion-abierta'>
                        <form id="frm_ePaycoCheckoutOpen" 
                            name="frm_ePaycoCheckoutOpen" 
                            method="POST" 
                            action="https://secure.payco.co/checkoutopen.php">
                            <input name="p_cust_id_cliente" type="hidden" value="978081"/>
                            <input name="p_key" type="hidden" value="22a5b3a6e038b6901591e752c0d501d0a1b80c5a"/>
                            <input name="p_id_factura" type="hidden" value=""/>
                            <input name="p_description" type="hidden" value="Donacion"/>
                            <input name="p_detalle" type="hidden" value="Al hacer esta donación, estás haciendo una contribución significativa al proceso de formación y orientación educativa de jóvenes provenientes del Pacífico caucano y otras zonas vulnerables de nuestro país. "/>
                            <input name="p_referencia" type="hidden" value="Numero de documento NIT o CC"/>
                            <input name="p_test_request" type="hidden" value="false"/>
                            <input name="p_url_respuesta" type="hidden" value=""/>
                            <input name="p_url_confirmacion" type="hidden" value=""/>
                            <input type="image" id="imagen" src="https://multimedia.epayco.co/dashboard/btns/btn3.png" alt=""/>
                            <input type="hidden" id="idboton" name="idboton" value="56790"/>                  
                        </form>
                        </div>
                </div>
                
                <div className='right-container-donar'>
                    <Lottie animationData = {weAreWorking} />
                </div>

            </div>
                  
        </div>
    );
};

