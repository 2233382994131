import Lottie from 'lottie-react';
import weAreWorking from  '../../../lotties/we are work/RobotButterFly.json'
import '../../../assets/styles/rte.css'


export const RegimenTribitarioEspecial = () => {
    return (
        <div className='main-container-rte'>
            <div className='left-container-rte'>
                <h2>Aún estamos trabajando en la construcción de este sitio web</h2>
               
            </div>
            
            <div className='rigth-container-rte'>
                <Lottie animationData = {weAreWorking}
                
                      className = 'style-lottie-rte'
          
                />
            </div>
        </div>
    );
};

