
import animationsLottie from './industria.json';
import educativeRobot from './educativeRobot.json';
import code from './Code.json';
import educations from './Educations.json';
import robotDown from './RobotDivDown.json';
import finalRobot from './FinalRobot.json';
import TresDPrinter from './TresDPrinter.json';
import estamosTrabajando from './EstamosTrabajando.json';

export const lottieFile = {
    animationsLottie,
    educativeRobot,
    code,
    educations,
    robotDown,
    finalRobot,
    TresDPrinter,
    estamosTrabajando,
}