import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

// import  imagen1 from '../public/../../../assets/img/img-historia/img-1.jpeg'
// import  imagen2 from '../public/../../../assets/img/img-historia/img-2.jpeg'
// import  imagen3 from '../public/../../../assets/img/img-historia/img-3.jpeg'
// import  imagen4 from '../public/../../../assets/img/img-historia/img-4.jpeg'
// import  imagen5 from '../public/../../../assets/img/img-historia/img-5.jpeg'
// import  mision1 from '../public/../../../assets/img/img-historia/mision-1.jpeg'
// import  mision2 from '../public/../../../assets/img/img-historia/mision-2.jpeg'
// import  mision3 from '../public/../../../assets/img/img-historia/mision-3.jpeg'
// import  mision4 from '../public/../../../assets/img/img-historia/mision-4.jpeg'
// import  mision5 from '../public/../../../assets/img/img-historia/mision-5.jpeg'


import '../../../assets/styles/sobreNosotros.css'

export const SobreNosotros = ({ title, description, image }) => {

    return (
        <div  className="main-container-sobre-nosotros general-container"> 
            <div className="lef-container-sobre-nosotros">
                    <div >
                        <h2 className='title-historia'>Nuestra historia</h2>
                    </div>
                        <p className='description-historia'>
                        En el año 2018, nace la Corporación CCTET, fruto de años 
                        de dedicación y esfuerzo en la transformación social del 
                        Pacífico Caucano. Su misión comenzó al enfocarse en brindar
                        orientación profesional a jóvenes de grados once, provenientes 
                        de diversas instituciones educativas del pintoresco municipio 
                        de Timbiquí, Cauca. Fue así como muchos de estos jóvenes 
                        lograron abrirse paso hacia un futuro prometedor al ingresar 
                        a prestigiosas universidades públicas del país. <br /><br />

                        La visión de CCTET no se detuvo ahí. En el año 2021, decidieron 
                        dar un salto tecnológico y adentrarse en el fascinante mundo de 
                        la robótica, llevando a cabo procesos formativos en el mismo municipio de 
                        Timbiquí. {/*Sin embargo, esto era solo el inicio de un viaje hacia el progreso
                            y la innovación. */}
                        </p>
            </div>
            <div className="rigth-foto-historia" id="#historia-foto">
                <Carousel>
                    <Carousel.Item interval={1000}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/img-1.jpeg"
                            alt="" 
                            className='img-style'
                        />                       
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/img-2.jpeg"
                            alt="" 
                            className='img-style'
                        />                         
                    </Carousel.Item >
                    <Carousel.Item interval={1000}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/img-3.jpeg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/img-4.jpeg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                    <Carousel.Item >
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/img-5.jpeg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className='historia-parte-2'>
                    <p className = 'left-description-historia-parte-2 lef-container-sobre-nosotros-2'>
                    En el año 2022, se forjó una alianza  
                    con la reconocida Corporación Manos Visibles, dando lugar 
                    al emocionante proyecto Front-end Pacífico. Este ambicioso 
                    programa tenía como objetivo formar a 50 jóvenes en el apasionante 
                    campo del desarrollo web. Una oportunidad sin precedentes para que 
                    estos talentos emergentes encontraran su vocación y se convirtieran 
                    en expertos del mundo digital.  <br /><br />

                    Pero la misión de CCTET no se detenía en Timbiquí.
                    Con el proyecto Valle del Naidí, un curso de programación 
                    y robótica, extendieron sus brazos de conocimiento hacia los 
                    municipios de Timbiquí y Guapi, en el departamento del Cauca.
                    Aquí, el objetivo era claro: capacitar a 180 niños y adolescentes,
                    inspirándolos a soñar en grande y explorar nuevas fronteras tecnológicas.<br /><br />
                    </p>
                
                    <p className='rigth-description-historia-parte-2'>
                    Así, el Centro de Ciencias Tecnologías y Estudios Territoriales del Pacífico Caucano (CCTET)
                    se convirtió en un faro de esperanza y transformación en la región. Un lugar 
                    donde la educación y la tecnología se dan la mano para construir un 
                    futuro lleno de oportunidades y posibilidades infinitas.  <br /><br />

                    CCTET, con sigla de pasión y dedicación, se posiciona como 
                    un referente en el Pacífico Caucano, inspirando a las generaciones 
                    venideras a alcanzar sus sueños y materializar sus metas. Un legado
                    que perdurará en el tiempo y continuará trazando un camino hacia el 
                    desarrollo y la excelencia en la región.
                    </p>
            </div>
            <br />
            <br />
            <br />
            <br />


            

            {/*MISIÓN*/}


            <div className="lef-container-sobre-nosotros">
                <div className="left-description-historia">
                    
                        <h2 className='title-mision'>Misión</h2>
                    
                        <p className='description-mision'>
                            Promover activamente la apropiación social y tecnológica en las comunidades dispersas del
                            departamento del Cauca y del Suroccidente colombiano, a través del desarrollo e incentivo de
                            procesos de formación inclusivos y participativos en el campo STEM. Nuestra organización se
                            compromete a introducir a la población en las dinámicas actuales, abarcando aspectos tecnológicos,
                            ambientales y culturales. <br /> <br />
                            
                            Buscamos estimular el compromiso y la participación activa de las
                            comunidades en su propio proceso de aprendizaje. Nuestro enfoque se centra en empoderar a
                            niños/niñas, jóvenes y colectivos étnicos, brindándoles las herramientas y conocimientos necesarios
                            para comprender y enfrentar los desafíos de su entorno.
                        </p>
                    
                </div>
            </div>
            
            <div className="rigth-foto-historia" id="#historia-foto">

                <Carousel>
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-1.jpeg"
                            alt="" 
                            className='img-style'
                        />                       
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-2.jpeg"
                            alt="" 
                            className='img-style'
                        />                         
                    </Carousel.Item >
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-3.jpeg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-4.jpeg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                    <Carousel.Item >
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-5.jpeg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                </Carousel>

            </div>


            {/* VISIÓN */}

             <div className="left-foto-vision" id="#historia-foto">

                <Carousel>
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-10.jpg"
                            alt="" 
                            className='img-style'
                        />                       
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-9.jpg"
                            alt="" 
                            className='img-style'
                        />                         
                    </Carousel.Item >
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-8.jpg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-7.jpg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                    <Carousel.Item >
                        <img 
                            src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-historia/mision-6.jpg"
                            alt="" 
                            className='img-style'
                        />                        
                    </Carousel.Item>
                </Carousel>

            </div>


            <div className="right-container-vision">
                <div className="left-description-historia" id="#historia-description">
                    <div className="">
                        <h2  className='title-vision'>Visión</h2>
                    </div>
                        <p className='description-vision'>
                            Promover activamente la apropiación social y tecnológica en las comunidades dispersas del
                            departamento del Cauca y del Suroccidente colombiano, a través del desarrollo e incentivo de
                            procesos de formación inclusivos y participativos en el campo STEM. Nuestra organización se
                            compromete a introducir a la población en las dinámicas actuales, abarcando aspectos tecnológicos
                            ambientales y culturales. Buscamos estimular el compromiso y la participación activa de las
                            comunidades en su propio proceso de aprendizaje. <br /> <br />
                            
                            Nuestro enfoque se centra en empoderar a
                            niños/niñas, jóvenes y colectivos étnicos, brindándoles las herramientas y conocimientos necesarios
                            para comprender y enfrentar los desafíos de su entorno.
                        </p>
                </div>
            </div>
             
            </div>
    );
}





