import '../../src/assets/styles/progressBarLottie.css';
import Lottie from 'lottie-react';
import {lottieFile} from '../lotties';


export  const ProgressBarLottie = (props) => {


    const {lottie, heigthLottie, widthLottie } = props;


  return (
    <div  className='main-container-progress-bar'>
        <div className='left-container-progress-bar'>
            {/* PONER AQUI EL LOTTIE */}
            <Lottie 
                animationData = {lottie}
                lottie  = {lottieFile}
                heigth  = {heigthLottie}
                width   = {widthLottie} 
            />
        </div>        
    </div>
  
  
  );
}

