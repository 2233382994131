import '../../src/assets/styles/horizontalLottie.css';
import Lottie from 'lottie-react';

export const  HorizontalLottie = ({title,description,lottie, widthLottie,heigthLottie // Las propuesdades width y heigth serán recibidas como propos para ajustar el tamaño de cada lottie de manera independiente

}) => {



    return(
        <div className='main-container-horizontal-lottie'>
            <div className='lottie'>
                <Lottie  animationData =  {lottie}
                         style={{width:100, height:100}}
                />
            </div>
            <div className='title-description'>
                <h2 className='title'>{title}</h2>
                <p className='text-description-programin'>{description}</p>
            </div>
        </div>
    );
}