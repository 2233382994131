
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


import '../../../assets/styles/nuestroProposito.css'

function newQuotes(){
    
    let quotes = [
        {
            author: "Ludwig von Mises",
            title: "La victoria se consigue apelando a la inteligencia, nunca por pura fuerza"
        },
        {
            author: "Chris Gardner",
            title: "Empieza donde estás"
        },
        {
            author: "Miguel de Cervantes Saavedra",
            title: "El hombre bien preparado para la lucha ya ha conseguido medio triunfo."
        },
        {
            author: "Eleanor Roosevelt",
            title: "Haz cada día una cosa que te asuste"
        },
        {
            author:"Ludwig Wittgenstein",
            title:"Si la gente nunca hiciera tonterías, nunca se haría nada inteligente."
        },
        {
            author:"Mary Anne Evans",
            title:"Nunca es demasiado tarde para ser lo que podrías haber sido."
        },
        {
            author:"Rumi",
            title:"Vende tu inteligencia y compra desconcierto."
        },
        {
            author:"Vincent van Gogh",
            title:"Las grandes cosas se logran mediante una serie de pequeñas cosas unidas."
        },
        {
            author:"Truman Capote",
            title:"El fracaso es el condimento que da sabor al éxito."
        },
        {
            author:"Lao Tzu",
            title:"Un buen viajero no tiene planes fijos y no tiene intención de llegar.."
        },
        {
            author:"John Muir",
            title:"El poder de la imaginación nos hace infinitos."
        },
        {
            author:"William Faulkner",
            title:"No puedes nadar hacia nuevos horizontes hasta que tengas el coraje de perder de vista la orilla."
        },
        {
            author:"Charles Bukowski",
            title:"Lo que más importa es lo bien que atraviesas el fuego."
        },
        {
            author:"Stephen Hawking",
            title:"Tanto si quieres descubrir los secretos del universo como si quieres desarrollar una carrera profesional en el siglo XXI, la programación informática básica es una habilidad esencial que debes aprender."
        },
        {
            author:"Albert Einstein",
            title:"Nunca memorices algo que puedas buscar."
        },
        {
            author:"",
            title:"Sigue tu luz de luna interior. No escondas la locura."
        },
        {
            author:"Maya Angelou",
            title:"Podemos encontrarnos con muchas derrotas, pero no debemos dejarnos vencer."
        }


    ];

    
    let randonQuotes= Math.floor(Math.random()*quotes.length);
    let quote = quotes[randonQuotes];
    document.getElementById("text-quotes").innerText = "\"" + quote.title + "\"";
    document.getElementById("author-quotes").innerText = "-" + quote.author;
    

   
}

export const NuestroProposito = () => {

    return (
        <div  className="main-container-nuestro-proposito" onLoad={newQuotes}> 
            <div className="lef-container-nuestro-proposito">

                        <h2 className='title-quienes-somos' > Quienes somos </h2>
                        <div >
                            <p className='description-quienes-somos'>
                                La Corporación Centro de Ciencias, Tecnologías y 
                                Estudios Territoriales del Pacífico Caucano (CCTET) 
                                es una entidad s in ánimo de lucro con autonomía propia, 
                                dedicada a generar estrategias de intervención y conocimiento 
                                en comunidades colombianas, especialmente las más vulnerables.
                                Su objetivo principal es fortalecer y apoyar la formación de niños, 
                                jóvenes y colectivos étnicos en el campo STEM (Ciencia, Tecnología, 
                                Ingeniería y Matemáticas), proporcionando recursos, programas y actividades 
                                para cerrar la brecha educativa y fomentar la inclusión en el 
                                ámbito científico y tecnológico.  <br /><br />

                                Además, promueve el conocimiento en ciencias sociales y ambientales, 
                                abordando desafíos actuales. A través de programas educativos y proyectos
                                de investigación, busca una ciudadanía activa y comprometida en la resolución 
                                de problemas sociales y ambientales.
                            </p>
                        </div>
            </div>
            <div className="rigth-foto-nuestro-proposito">
                <img src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-proposito/quienes-somos-1.jpeg" className='img-rigth-foto-nuestro-proposito' alt="imagen de participantes curso de robótica Guapi 2023" fluid />
            </div>  


                {/*NUESTRO PROPÓSITO O PRPÓSITO DE CCTET*/}

            <div className="lef-container-nuestro-proposito" style={{marginTop:'5em'}}>

                <h2 className='title-nuestro-proposito'> Nuestro propósito </h2>
            
                <p className='description-nuestro-proposito'>
                    CCTET tiene como propósito brindar educación No formal 
                    en áreas STEM, pensamiento algorítmico, robótica educativa
                    y orientación profesional a niños, niñas, adolescentes y 
                    jóvenes de la Costa Pacífica caucana y del Suroccidente colombiano, 
                    con el fin de generar nuevos espacios de preparación y fortalecimiento de las 
                    capacidades educativas y tecnológicas que permitan el 
                    mejoramiento y adquisición de habilidades que conlleven
                    a la obtención de mayores puntajes en en el examen de 
                    Estado, realizadas por el Instituto Colombiano para la 
                    Evaluación de la Educación (ICFES) y así cerrar
                    la brecha de acceso y permanencia en la educación
                    superior <br /> <br />
                    
                    "Ya sea que desee descubrir los secretos del universo o quiera
                     seguir una carrera en el siglo XXI, la programación básica de 
                     computadoras es una habilidad esencial para aprender." - Stephen Hawking
                </p>
            </div>
            <div className='rigth-foto-nuestro-proposito ' style={{marginTop:'8%'}}>
                <img src="https://images-cctet-project.s3.us-west-2.amazonaws.com/img-proposito/imgproposito-2.jpeg" className='img-rigth-foto-nuestro-proposito' alt="" fluid />
                <div className='quotes-container'>
                    <div className='text-quotes-container'>
                      <h2 id='text-quotes'className='text-quotes'></h2>
                    </div>
                    <div className='author-quotes-container' >
                        <p id='author-quotes'></p>
                    </div>                                
                    <div className='button-quotes-container'>
                    <Button variant="dark"onClick={newQuotes}>Descubre una nueva frase</Button>
                    </div>    
                </div>
                
            </div> 

            
        </div>
    );
}





