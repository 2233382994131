import React from 'react';
import { Menubar } from 'primereact/menubar';
import 'primereact/resources/themes/lara-light-indigo/theme.css';  //theme
import 'primereact/resources/primereact.min.css';                  //core css
import 'primeicons/primeicons.css';                                //icons
import  '../../src/assets/styles/menuHeader.css';
import cctetLogo from '../../src/assets/img/cctetLogo.png';
import iconoInstagram from '../../src/icons/instagram.png';
import iconoFacebook from '../../src/icons/facebook.png';



export const MenuHeader = ( { lottie }
  
  ) => {

   const  submenuClass = 'submenu-left'; // Se define el estilo CSS para ajustar el texto del submenú a la izquierda 
    
    // CREAMOS EL MENÚ DE NAVEGACIÓN
    const items =[
        {
          label: 'Inicio',
          command: () =>{
            window.location.href = '/inicio';
          }
        },
        {
            label: 'Sobre Nosotros', 
                   
            items:[
                {
                    label:'Nuestra historia',
                    command: () => {
                      window.location.href = '/historia';
                    }, 
                },
                {
                    label:'Nuestro propósito',
                    command: () =>{
                      window.location.href = '/proposito';
                    }
                   
                },
                {
                    label:'Equipo CCTET',
                    command: () => {
                      window.location.href = '/equipo-cctet';
                    }
                },
               
                {
                    label:'Régimen TE',
                    command: () => {
                      window.location.href = '/rte';
                    }
                },   
            ],
            className: submenuClass,             
        },
        
     //   {separator:true},
      //  {
      //      label:'OEL edtech',

            //items:[
             //   {
              //      label:'Curso de diseño web adaptativo "HTML y CSS"'
             //   },
             //   {
             //       label:'Curso de Algoritmos de Javascript y estructuras de datos'
             //   },
             //  {
             //       label:'Cursos de IoT y electrónica'
             //   },
             //   {
             //       label:'Curso de Programación por bloques'
             //   },
             //   {
             //       label: 'Cursos de robótica educativa'

             //   },
             //   {
             //       label: 'Curso de matemáticas generales'
             //   },
             //   {
             //       label:'Curso de física básica'
             //   },
             //   {
             //     label: 'Oportunidades laborales'
             //   }
           // ]
     //   },
        {separator:true},
        {
          label:'Plataforma Univeritaria de Asesoría Mundial (PUAM)',
          /*
          items: [
            {
              label: 'Orientación profesional'
            },
            {
              label: 'Fortalecimiento ICFES'
            },
            {
              label: 'Test de nivelación y seguimiento'
            },
            {
              label: 'Acompañamiento y apoyo universitario'
            },
            {
              label:'Solicitar asesoria universitaria',
            }
          ]
         */
        },
        //{separator:true},
        //{
          //label:'Tienda online',
        //},
        /*{
          label:'Registrarse'
          
        },*/
        {
          label:'Donar',
          command: () => {
            window.location.href = '/donar';
          }
              
        }
    ]

   
  
    return(

        <div className='div-container-menu-header'>
          {/*REDES SOCIALES*/}
            {/*Imstagram */}
            <div className='div-up-bar' style={{display:'flex', justifyContent:'center'}}>
              <a href='https://www.instagram.com/cctet_corporacion/' target="_blank" >
                <img src= {iconoInstagram}/>
              </a>

              {/*Facebook */}
              <a  href='https://www.facebook.com/profile.php?id=100083510792987&mibextid=LQQJ4d&rdid=tupBF9e2VmNfgr75' target='_blanck'>
                <img src= {iconoFacebook}/>
              </a> 
             
            </div>

            <Menubar  className='menu-header p-menuitem general-container'
              start = {<img src= {cctetLogo} alt='Logo CCTET' className='menuheader-logo'/>}
              model = {items} 
              />
        </div>
    );

}