


import React from 'react';
import  '../../src/assets/styles/footer.css';

const atualYear = new Date();


export const FooterSheet = () => { 
    return(

        <div className='div-footer'>
         
            <div className='copi'>
                <p> &copy; copyright  CCTET {atualYear.getFullYear()}</p>
                <p> Desarrollado por: aprendices CCTET</p>
            </div>

         
            <div className='privacidad'>
            <h2 className='h2-subtitle'>PRIVACIDAD</h2>      
                    <p>Política de tratamiento de datos</p>
                    <p>Terminos y condiciones </p>
            </div>

            <div className='direccion'>
                <h2 className='h2-subtitle'>LUGARES DE OPERACIÓN</h2>
                    <p>Timbiquí: Cra 4 # 822, Diagonal Polideportivo </p>
                    <p>Guapi: Laboratorio tecnología I.E Normal Superior </p>
                    <p>Cel: 3102836048</p>
                    <p>E-mail: gerentetic@cctet.org</p>
            </div>

            <div className='direccion-principal'>
                <h2 className='h2-subtitle'>OFICINA PRINCIPAL</h2>
                    <p>Lugar de operación: Calle 73 N 34-95 Popayán </p>
                    <p>Cel: 3116072750</p>
                    <p>E-mail: gerencia@cctet.org</p>
            </div>

            <div className='entidad'>
                <h2 className='h2-subtitle'>ENTIDAD</h2>
                    <p>Corporación CCTET</p>
            </div>

        </div>       
    );
}

