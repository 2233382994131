import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import  '../../src/assets/styles/nuestrosAliados.css';
import logoInndico from '../logos-aliados/inndico.png';
import logoManosVisibles from '../logos-aliados/logoManosVisibles.png';
import universidadDelCauca from '../logos-aliados/universidad-de-cauca.png';
import laAlianza from '../logos-aliados/LaAlianza.png'
import googleForNonprofits from '../logos-aliados/GoogleForNonProfit.png'
import acenture from '../logos-aliados/acenture.png';
import onesoft from '../logos-aliados/OneSoft.png'
import koulutus from '../logos-aliados/koulutus.svg'


export const NuestrosAliados =() => {

  return ( 

    <div className='main-container-our-llies'>
      <div >
        <h3 style={{fontSize:'40px',lineHeight:'48px'}} className='title-our-allies'>Ellos confían en nosotros</h3>
      </div>
      <div>
        <p style={{fontSize:'16px',lineHeight:'30px',fontWeight:'400',color:'rgba(30, 55, 90, 0.7)'}}>Fortalecer las áreas STEM en edades tempranas ayuda a desarrollar habilidades educativas y tecnológicas para el futuro.
        </p>
      </div>


      {/* REPITE ESTE BLOQUE DE CÓDIGO PARA PONER OTRA FILA DE ALIADOS */}
      <div className='div-img-logos-aliados'>


        <div>
          <img src={ logoManosVisibles } alt="Logo corporacion manos visibles" className='img-logos-aliados'/>
        </div>
       

        <div>
          <img src={ logoInndico } alt="Logo empresa inndico" className='img-logos-aliados'  />
        </div>
       
        <div>
          <img src={ laAlianza } alt="Logo la alianza para la transformación social" className='img-logos-aliados'  />
        </div>

        <div>
          <img src={ acenture } alt="Logo accenture" className='img-logos-aliados' />
        </div>
        <div>
          <img src={ googleForNonprofits } alt="Logo google for nomprofit" className='img-logos-aliados' />
        </div>

       
        <div>
          <img src={ universidadDelCauca } alt="Logo universidad del cauca" className='img-logos-aliados' style={{width:'130px', height:'auto'}} />
        </div>

        <div>
          <img src={ koulutus } alt="Logo corporación Koulutus" className='img-logos-aliados' style={{width:'190px', height:'auto', paddingTop:'2%'}} />
        </div>

        <div>
          <img src={ onesoft } alt="Logo onesoft" className='img-logos-aliados' style={{width:'190px', height:'auto', paddingTop:'2%'}} />
        </div>

      </div>  
        
    </div>
  );
}
