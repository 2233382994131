
import { BodyCctet } from './../../../components/body';
import { HorizontalLottie } from './../../../components/horizontalLottie';
import { lottieFile } from './../../../lotties';
import {ProgresBarr}  from './../../../components/progressbar';
import { ProgressBarLottie } from './../../../components/progressBarLottie';
import { Metricas } from './../../../components/metricas';
import { NuestrosAliados } from './../../../components/nuestrosAliados';
import '../../../../src/App.css';


export const  MainView = () => {
  return (

    <div className="App">
     <div className='container-up'>
        <BodyCctet
          title = 'Generamos oportunidades educativas  y laborales  a través de la tecnología'
          description = 'La brecha digital y tecnológica es un problema importante que afecta a muchas comunidades en todo el mundo, y el Pacífico colombiano no es una excepción. Para cerrar esta brecha, es necesario abordar varios desafíos, incluyendo el acceso a la tecnología, la conectividad, la educación y la capacitación en habilidades digitales.'      
          lottie={lottieFile.animationsLottie} // Se envían Lottie como Props 
        />
        <BodyCctet
          title = 'Cursos de robótica educativa'
          description = 'La robótica educativa es una disciplina que utiliza robots y otras tecnologías para enseñar conceptos de ciencia, tecnología, ingeniería y matemáticas (STEM) de manera interactiva y divertida. La robótica educativa tiene como objetivo principal promover el aprendizaje y el desarrollo de habilidades importantes en los estudiantes, como la resolución de problemas, el pensamiento crítico, la creatividad y el trabajo en equipo.'      
          lottie={lottieFile.educativeRobot}// Se envían Lottie como Props 
        />
      </div>

     {/* Ubicacion de Lotties horizontales*/}
     <div className='container-div-horizontal-lottie'>
          <HorizontalLottie 
            lottie={lottieFile.educations}
            title={'Educación'}
            description={'CCTET busca promover la transformación social a través del fortalecimiento de las habilidades STEM en la educación. Con esto se busca formar ciudadanos críticos y capaces de contribuir al desarrollo sostenible y tecnológico del país. '} // Se envian como Props al Componente HorizontalLottie.jsx
            heigthLottie = {130}
            widthLottie = {130} 
          />    
          <HorizontalLottie 
            lottie={lottieFile.robotDown}
            title={'Robótica'}
            description={'La robótica es importante porque permite desarrollar habilidades técnicas, de programación y de trabajo en equipo en los niños, niñas y adolescentes. Además, al aplicarse en áreas como la educación, la salud y la industria, promueve el aprendizaje activo y fomenta la innovación en el futuro.'}
            heigthLottie = {100}
            widthLottie = {100}
          />     
          <HorizontalLottie
            lottie={lottieFile.code}
            title={'Programación'}
            description={'La programación es una herramienta poderosa para solucionar problemas y desarrollar soluciones innovadoras en diferentes campos.'}
            heigthLottie = {100}
            widthLottie = {100}
          />    
      </div>

      {/*PROGERSS BAR PERCENT AND LOTTIE*/}

      <div className='main-container-progress-bar-app'>
        {/* Ubicción del componente <Lottie /> animation al lado izquierdo de la pantalla*/}
        <div className='main-container-progress-bar-app-left-lottie'>
          <ProgressBarLottie 
            lottie = {lottieFile.TresDPrinter}  // Se envía el Lottie animation como parámetro
          />
        </div>
          {/* Ubicción las barras de progreso del componenete <ProgressBar /> inmerso dentro del componente <DinamyDemo />*/}
        <div className='main-container-progress-bar-app-rigth'>
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            maxProgress = {90}
            now = {90}
            subtitle = 'Beneficios que obtienen los niños, niñas, adolescentes y jóvenes que hacen parte de nuestros procesos de formación STEM:'
            title = 'Conexiones'
            description = 'Desarrollo del pensamiento lógico'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {70}
            maxProgress = {80}
            subtitle = ''
            title = 'Conexiones'
            description = 'Mejora de la creatividad'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {100}
            maxProgress = {95}
            subtitle = ''
            title = 'Conexiones'
            description = 'Fomento del trabajo en equipo'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {100}
            maxProgress = {90}
            subtitle = ''
            title = 'Conexiones'
            description = 'Resolución de problemas del mundo real'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {100}
            maxProgress = {93}
            subtitle = ''
            title = 'Conexiones'
            description = 'Incremento de la confianza y autoestima'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {70}
            maxProgress = {80}
            subtitle = ''
            title = 'Conexiones'
            description = 'Mejora de habilidades matemáticas y científicas'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {70}
            maxProgress = {60}
            subtitle = ''
            title = 'Conexiones'
            description = 'Preparación para futuras carreras'
          />
          <ProgresBarr
          // Se envían los parámetros como Props al componente <DynamicDemo/> del archivo progresbar.jsx
            now = {70}
            maxProgress = {50}
            subtitle = ''
            title = 'Conexiones'
            description = 'Habilidades en demanda para el futuro'
          />
        </div>
      </div>

      {/*Renderiza las métirxas del proyectos* */}
      <Metricas />
      {/*Renderiza las los logos de los aliados de CCTET */}
      
        <NuestrosAliados />
      
    </div>
  );
}


