import Lottie from 'lottie-react';
import weAreWorking from  '../../../lotties/we are work/working.json'
import '../../../assets/styles/nuestroEquipo.css'

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import VivianaGahona from '../../../../src/assets/img/nuestro-equipo/Viviana_Gahona.png';
import irne_Fernando from '../../../../src/assets/img/nuestro-equipo/Irne_Fernando.png';
import IsabelSanchez from  '../../../../src/assets/img/nuestro-equipo/Isabel_Sanchez_Medina.png';
import Jeison_Pantoja from  '../../../../src/assets/img/nuestro-equipo/Jeison_Pantoja.png';
import KleverArrechea  from '../../../../src/assets/img/nuestro-equipo/Klever_Arrechea.png'




export const NuestroEquipo = () => {
    return (

        <div className='main-container-oure-team'>
            {/*
            <div className='left-container-our-team'>
                <h2>Aún estamos trabajanViviana Gahona Gutierrezdo en la construcción de este sitio web</h2>
               
            </div>
            
            <div className='rigth-container-our-team'>
                <Lottie animationData = {weAreWorking}
                      className  = 'style-lottie'
                />
            </div>
            */}
            
            <h2 className='style-nuestro-equipo'>CCTET,  TRANFORMAMOS VIDAS E INSPIRAMOS MENTES</h2>

            <div  className='main-container'>
              <div className='container-img-title-description-persona'> 
              <div className='container-img-person'>
                <Container>
                  <Image  src= {VivianaGahona} roundedCircle />
                </Container>
              </div>
              <h3>Viviana Gahona Gutierrez</h3>
              <h4>Presidenta</h4>
              </div>
            </div>

            <div  className='main-container'>
              <div className='container-img-title-description-persona'> 
              <div className='container-img-person'>
                <Container>
                  <Image  src= {IsabelSanchez} roundedCircle />
                </Container>
              </div>
              <h3>Isabel C. Sanchez Medina</h3>
              <h4>Vicepresidenta</h4>
              </div>
            </div>

            <div  className='main-container'>
              <div className='container-img-title-description-persona'> 
              <div className='container-img-person'>
                <Container>
                  <Image src= {KleverArrechea} roundedCircle />
                </Container>
              </div>
              <h3>Klever Arrechea Castillo</h3>
              <h4>CTO</h4>
              </div>
            </div>


            <div  className='main-container'>
              <div className='container-img-title-description-persona'> 
              <div className='container-img-person'>
                <Container>
                  <Image src= {irne_Fernando} roundedCircle />
                </Container>
              </div>
              <h3>Irne Fernando Hinestroza</h3>
              <h4>Gerente Financiero</h4>
              </div>
            </div>

            <div  className='main-container'>
              <div className='container-img-title-description-persona'> 
              <div className='container-img-person'>
                <Container>
                  <Image src= {Jeison_Pantoja} roundedCircle style={{width:'22rem'}}/>
                </Container>
              </div>
              <h3>jeison Pantoja Andino</h3>
              <h4>Director de Programación y Robótica</h4>
              </div>
            </div>

 
        </div>



    )
}




