

import './App.css';
import { MenuHeader } from './components/menuHeader';

import { FooterSheet } from './components/footerSheet';
import { SobreNosotros } from './components/views/public/sobreNosotros';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MainView } from './components/views/public/main';
import { NuestroProposito } from './components/views/public/nuestroProposito';
import { NuestroEquipo } from './components/views/public/nuestroEquipo';
import { RegimenTribitarioEspecial } from './components/views/public/rte';
import { Donar } from './components/views/public/donar';

function App() {
  return (

    <div className="App">
        <Router> 
        <MenuHeader />
          <Switch>
            <Route path = '/donar' component = { Donar } />
            <Route path = '/rte' component = { RegimenTribitarioEspecial } />
            <Route path = '/equipo-cctet' component = { NuestroEquipo } />
            <Route path = '/proposito' component = { NuestroProposito } />
            <Route path = '/historia' component = { SobreNosotros } />
            <Route exact path = '/' component = { MainView } />
            <Route exact path = '/inicio' component = { MainView } />
          </Switch>
        </Router> 
      
      <FooterSheet />  
      
    </div>
  );
}

export default App;
