import '../../src/assets/styles/body.css';
import Lottie from 'lottie-react';

export const  BodyCctet = ({title,description,lottie, widthLottie,heigthLottie // Las propuesdades width y heigth serán recibidas como propos para ajustar el tamaño de cada lottie de manera independiente

}) => {

  

    return(
          <div className="main-container-body">
            <div className="main-container-left">
                <div className='container-title'>
                    <h1>{title}</h1>
                </div>

                <div className='container-description'>
                    <p style={{textAlign:'justify'}}>{description}</p>
                </div>
            </div>
            
            <div className='main-container-rigth'>
                <div className = 'main-container-rigth-lottie'>
                    <Lottie 
                        animationData = {lottie}
                        heigth  = {heigthLottie}
                        width   = {widthLottie} 
                    />
                </div>         
            </div>

          </div>
    );
}